<template>
  <div>

    <CRow>
      <CCol lg="12">
        <h1 class="main-header" v-translate translate-context="roles">
          Role List
        </h1>
        <CCard class="">
          <CCardHeader>
            <div class="card-header-actions">
              <router-link :to="{name: 'roles.new'}" v-if="hasPerm('admin.role.create')">
                <CButton block color="primary" v-translate translate-context="list.actions">Create</CButton>
              </router-link>
            </div>
          </CCardHeader>
          <CCardBody class="px-0">
            <AjaxTable
                :fields="fields"
                :data="roles"
                :fetch="fetchRoleList"
            >
              <template #actions="{item}">
                <td class="text-right">
                  <CButton variant="outline" color="primary" class="mr-2"
                           v-if="hasPerm('admin.role.update')"
                           @click="$router.push({name: 'roles.edit', params: {id: item.id}})"
                           v-translate translate-context="list.actions"
                  >
                    Edit
                  </CButton>
                  <CButton @click="onDelete(item.id)" variant="outline" color="danger"
                           v-if="hasPerm('admin.role.delete')"
                           v-translate translate-context="list.actions"
                  >
                    Delete
                  </CButton>
                </td>
              </template>
            </AjaxTable>
          </CCardBody>
        </CCard>
      </CCol>


    </CRow>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AjaxTable from "@/domain/core/components/AjaxTable.vue";

export default {
  name: 'ListRole',
  components: {AjaxTable},

  data() {
    return {
      fields: [
        {key: 'id', label: this.$pgettext('roles.list', 'ID'), _style: 'width:100px;'},
        {key: 'name', label: this.$pgettext('roles.list', 'Name')},
        {key: 'actions', label: this.$pgettext('roles.list', 'Actions'), sorter: false, filter: false}
      ],
    }
  },
  async mounted() {
    await this.fetchConfig({type: 'admin'})
  },
  computed: {
    ...mapGetters('roles', [
      'roles',
    ]),
  },
  methods: {
    ...mapActions('roles', [
      'fetchRoleList',
      'deleteRole'
    ]),
    ...mapActions('login', [
      'fetchConfig'
    ]),
    onDelete(id) {
      this.confirm({
        title: this.$gettext('Delete Role'),
        message: this.$gettext('Are you sure you want to delete this role?'),
        cb_confirm: async () => {
          await this.deleteRole(id)
        }
      })
    }
  }
}
</script>